import { t } from "@lingui/macro";

export interface MenuSection {
  title: string;
  items: MenuItem[];
  closeMenu?: () => void;
}

export interface MenuItem {
  label: string;
  href: string;
  internal?: boolean;
  overflow?: boolean;
  comingSoon?: boolean;
  closeMenu?: () => void;
}

export const menuContent: MenuSection[] = [
  {
    title: "",
    items: [
      {
        label: "Faucet",
        href: "https://faucet.trade/blast-sepolia-eth-faucet",
      },
      {
        label: "Early Supporter NFT",
        href: "https://medium.com/@NeptuneX_io/neptunex-launches-early-supporter-campaign-and-chance-to-grab-nft-353638cc3c6d",
      },
      {
        label: "Earn",
        href: "https://rewards.neptunex.io/",
      },
      {
        label: "Governance",
        href: "",
        comingSoon: true,
      },
    ],
  },
];

import { WebsiteIcon } from "../../assets/icons/Website";
import { DocsIcon } from "../../assets/icons/DocsIcon";
import { ZealyIcon } from "../../assets/icons/ZealyIcon";
import { DiscordIcon } from "../../assets/icons/DiscordIcon";
import { TwitterIcon } from "../../assets/icons/TwitterIcon";

import styled from "styled-components";
import { Link } from "react-router-dom";

const CommunityLinksWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  width: 100%;
`;

const CommunityLink = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  gap: 8px;
  text-decoration: none;
  color: ${({ theme }) => theme.neutral2};
  transition: color 0.3 linear;

  &:hover {
    color: ${({ theme }) => theme.accent1};
    opacity: 1;
  }
`;

const COMMUNITY_LINKS = [
  { name: "Website", icon: WebsiteIcon, url: "https://neptunex.io" },
  { name: "Docs", icon: DocsIcon, url: "https://docs.neptunex.io" },
  {
    name: "Twitter",
    icon: TwitterIcon,
    url: "https://twitter.com/NeptuneX_io_/",
  },
  { name: "Discord", icon: DiscordIcon, url: "https://discord.gg/NeptuneX" },
  {
    name: "Zealy",
    icon: ZealyIcon,
    url: "https://zealy.io/cw/neptunex/questboard",
  },
];

export const CommunityLinks = () => {
  return (
    <CommunityLinksWrapper>
      {COMMUNITY_LINKS.map(({ name, url, icon: Icon }) => (
        <CommunityLink
          key={name}
          rel="noreferrer noopener"
          target="_blank"
          to={url}
        >
          <div>{name}</div>
          {Icon && <Icon />}
        </CommunityLink>
      ))}
      <div>
        <div>Routing Algorithm: v0.3</div>
        <div>dApp: 1.0.1</div>
      </div>
    </CommunityLinksWrapper>
  );
};

import styled from "styled-components";


import { ReactComponent as Logo } from '../../assets/svg/strato-dex-logo.svg'


// ESLint reports `fill` is missing, whereas it exists on an SVGProps type
export type SVGProps = React.SVGProps<SVGSVGElement> & {
  fill?: string;
  height?: string | number;
  width?: string | number;
  gradientId?: string;
  clickable?: boolean;
};

export const UniIcon = ({ clickable, ...props }: SVGProps) => (
  <Container clickable={clickable}>
    <Logo {...props} />
  </Container>
);

const Container = styled.div<{ clickable?: boolean }>`
  position: relative;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "auto")};
`;

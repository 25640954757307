type Props = {
  className?: string;
};

export const TwitterIcon = (props: Props) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2685 8.0452L18.1896 0H16.5496L10.54 6.98554L5.74014 0H0.204102L7.46239 10.5634L0.204102 19H1.84427L8.19054 11.623L13.2595 19H18.7956L11.2681 8.0452H11.2685ZM9.02211 10.6564L8.28669 9.60456L2.43525 1.23469H4.95445L9.67663 7.98944L10.412 9.04132L16.5503 17.8215H14.0311L9.02211 10.6568V10.6564Z"
        fill="currentColor"
      />
    </svg>
  );
};

type Props = {
  className?: string;
};

export const ZealyIcon = (props: Props) => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0529 0.368837C16.212 1.26989 13.3458 1.81122 9.6782 2.12973C8.86428 2.20046 8.17094 2.21651 5.47777 2.21651H1.32175L1.35029 10.0876L2.43679 11.1756L2.45788 11.1968L0.745883 12.28L0.686122 15.5319L0.664062 18.6593L5.90932 23.9382L7.18857 24L12.3279 23.9814C13.3858 23.9775 14.4481 23.9111 15.5064 23.7623C17.8281 23.4359 22.3818 22.7401 24.6543 21.9867L25.9348 21.5622L25.9649 13.8955L24.6432 12.572L25.6082 11.5974V5.41915L20.2157 0L19.0529 0.368837ZM25.0258 20.9032L24.3675 21.1214C22.1898 21.8434 17.7269 22.5296 15.3795 22.8596C14.3673 23.0019 13.3467 23.0661 12.3246 23.0698L7.20893 23.0884L6.30663 23.0447L1.57828 18.2861L1.59763 15.5435L1.64827 12.7877L3.88014 11.3756C3.88014 11.3756 3.61494 11.0758 3.24619 10.698C3.19335 10.6439 3.13838 10.5882 3.08176 10.5315L2.26049 9.70903L2.23664 3.12808H5.47777C8.16324 3.12808 8.89685 3.11263 9.75707 3.03788C13.4775 2.71478 16.4115 2.16293 19.3285 1.23774L19.9615 1.03694L24.6966 5.79542V11.2225L23.3576 12.5748L25.0519 14.2713L25.0258 20.9032ZM22.8953 9.4902V10.8133L22.3509 11.3298C20.0229 13.5381 17.5261 15.6658 14.8314 17.7377C14.4517 18.0297 14.1473 18.2749 14.155 18.2826C14.1761 18.3037 15.8414 18.1527 16.5642 18.064C18.6152 17.8126 19.9705 17.5593 21.8825 17.0703C22.537 16.903 23.1181 16.7626 23.1739 16.7585C23.273 16.7511 23.2751 16.7822 23.2751 18.194V19.6371L23.0726 19.7038C22.675 19.8348 21.335 20.1647 20.3885 20.3647C18.0942 20.8495 15.7745 21.1477 13.2479 21.2825C12.1871 21.3391 8.71432 21.3528 8.34266 21.3019L8.10768 21.2697V19.9587V18.6477L8.5508 18.3631C10.674 16.9994 13.0946 15.2873 15.2081 13.6543C16.5179 12.6423 16.8723 12.3569 16.8485 12.3331C16.8377 12.3223 16.6498 12.3371 16.431 12.366C14.7001 12.5947 12.3994 12.7008 9.61429 12.6804L8.76603 12.6742V11.2123V9.75035L11.2349 9.72179C13.1057 9.70016 13.9858 9.66831 14.8685 9.59032C17.4985 9.35792 19.6167 9.00768 21.7812 8.44727C22.3522 8.29944 22.8364 8.17587 22.8573 8.17273C22.8783 8.16959 22.8953 8.76242 22.8953 9.4902Z"
        fill="currentColor"
      />
    </svg>
  );
};

type Props = {
  className?: string;
};

export const WebsiteIcon = (props: Props) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.4167 4.53131V1.58314C17.4167 1.14614 17.0628 0.791476 16.625 0.791476C16.1872 0.791476 15.8333 1.14614 15.8333 1.58314V3.45623L11.7143 0.676684C10.3693 -0.230566 8.63075 -0.230566 7.28571 0.676684L1.74404 4.41652C0.652333 5.15356 0 6.37985 0 7.69798V15.0415C0 17.2241 1.77571 18.9998 3.95833 18.9998H6.33333C6.77113 18.9998 7.125 18.6451 7.125 18.2081V11.8748C7.125 11.4386 7.47967 11.0831 7.91667 11.0831H11.0833C11.5203 11.0831 11.875 11.4386 11.875 11.8748V18.2081C11.875 18.6451 12.2289 18.9998 12.6667 18.9998H15.0417C17.2243 18.9998 19 17.2241 19 15.0415V7.69798C19 6.44635 18.411 5.27706 17.4167 4.53131ZM17.4167 15.0415C17.4167 16.3509 16.3511 17.4165 15.0417 17.4165H13.4583V11.8748C13.4583 10.5654 12.3928 9.49981 11.0833 9.49981H7.91667C6.60725 9.49981 5.54167 10.5654 5.54167 11.8748V17.4165H3.95833C2.64892 17.4165 1.58333 16.3509 1.58333 15.0415V7.69798C1.58333 6.9071 1.97442 6.17085 2.62992 5.7291L8.17158 1.98927C8.97908 1.4446 10.0209 1.4446 10.8276 1.98927L16.3693 5.7291C17.0248 6.17085 17.4159 6.9071 17.4159 7.69798L17.4167 15.0415Z"
        fill="currentColor"
      />
    </svg>
  );
};

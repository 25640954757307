import Column from "components/Column";
import { ScrollBarStyles } from "components/Common";
import Row from "components/Row";
import { Socials } from "pages/Landing/sections/Footer";
import { Link } from "react-router-dom";

import styled, { css } from "styled-components";
import { BREAKPOINTS } from "theme";
import { ExternalLink, ThemedText } from "theme/components";

import { menuContent, MenuItem, MenuSection } from "./menuContent";
import { CommunityLinks } from "components/community-links";

const Container = styled.div`
  width: 295px;
  padding: 24px;
  user-select: none;
  overflow: auto;
  ${ScrollBarStyles}
  height: unset;
  margin-bottom: 24px;
`;

const StyledInternalLink = styled(Link)<{ canHide?: boolean }>`
  font-size: 16px;
  text-decoration: none;
  color: ${({ theme }) => theme.neutral2};
  &:hover {
    color: ${({ theme }) => theme.accent1};
    opacity: 1;
  }

  @media screen and (max-width: ${BREAKPOINTS.md}px), (min-width: ${BREAKPOINTS.xl}px) {
    display: ${({ canHide }) => (canHide ? "none" : "block")};
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.surface3};
`;

const ComingSoonMenuItem = styled.div`
  position: relative;
  color: #ffffffb3;
  margin-bottom: 8px;
`;

const ComingSoonLabel = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.accent1};
  font-size: 12px;
  left: 0;
  bottom: -14px;
`;

function Item({
  label,
  href,
  internal,
  overflow,
  closeMenu,
  comingSoon,
}: MenuItem) {
  if (comingSoon) {
    return (
      <ComingSoonMenuItem>
        {label}
        {comingSoon && <ComingSoonLabel>Soon</ComingSoonLabel>}
      </ComingSoonMenuItem>
    );
  }

  return (
    <StyledInternalLink
      to={href}
      canHide={overflow}
      onClick={closeMenu}
      target={!internal ? "_blank" : undefined}
      rel={!internal ? "noopener noreferrer" : undefined}
    >
      {label}
    </StyledInternalLink>
  );

}
function Section({ title, items, closeMenu }: MenuSection) {
  return (
    <Column gap="sm">
      <ThemedText.SubHeader>{title}</ThemedText.SubHeader>
      {items.map((item, index) => (
        <Item
          key={`${title}_${index}}`}
          label={item.label}
          href={item.href}
          internal={item.internal}
          overflow={item.overflow}
          comingSoon={item.comingSoon}
          closeMenu={closeMenu}
        />
      ))}
    </Column>
  );
}
export function Menu({ close }: { close: () => void }) {
  return (
    <Container>
      <Column gap="lg">
        {menuContent.map((sectionContent, index) => (
          <Section
            key={`menu_section_${index}`}
            title={sectionContent.title}
            items={sectionContent.items}
            closeMenu={close}
          />
        ))}
        <Separator />
        <CommunityLinks />
      </Column>
    </Container>
  );
}

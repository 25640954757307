import { useState } from "react";
import { MoreHorizontal } from "react-feather";

import { Menu } from "./Menu";
import { DropdownSelector } from "components/DropdownSelector";
import { css } from "styled-components";

const buttonStyles = css`
  padding: 2px 14px;
`

export function More() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownSelector
      isOpen={isOpen}
      toggleOpen={() => setIsOpen((prev) => !prev)}
      internalMenuItems={<Menu close={() => setIsOpen(false)} />}
      menuLabel={<MoreHorizontal width={20} height={20} />}
      hideChevron
      buttonCss={buttonStyles}
    />
  );
}

type Props = {
  className?: string;
};

export const DocsIcon = (props: Props) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.575 1.71238C17.13 1.34024 16.6084 1.07064 16.0475 0.92274C15.4865 0.774836 14.8998 0.75225 14.3292 0.856584L11.3034 1.406C10.6048 1.53429 9.96969 1.89369 9.5 2.42646C9.02909 1.89277 8.39211 1.53328 7.69183 1.406L4.67083 0.856584C4.10017 0.752159 3.51352 0.774449 2.95242 0.921878C2.39133 1.06931 1.86949 1.33827 1.42386 1.70973C0.978236 2.08118 0.619706 2.54606 0.373653 3.07144C0.127599 3.59681 3.86432e-05 4.16986 0 4.75L0 13.2945C4.54859e-05 14.2213 0.325357 15.1188 0.919231 15.8305C1.5131 16.5421 2.33788 17.0228 3.24979 17.1887L8.22621 18.0935C9.06851 18.2466 9.93149 18.2466 10.7738 18.0935L15.7542 17.1887C16.6654 17.0219 17.4892 16.5409 18.0823 15.8294C18.6754 15.1178 19.0001 14.2208 19 13.2945V4.75C19.0004 4.17006 18.8729 3.59717 18.6265 3.07214C18.3802 2.5471 18.0212 2.08283 17.575 1.71238ZM8.70833 16.568C8.64183 16.5585 8.57533 16.5474 8.50883 16.5355L3.53321 15.6315C2.98598 15.5319 2.49105 15.2434 2.13472 14.8163C1.77839 14.3893 1.58325 13.8507 1.58333 13.2945V4.75C1.58333 4.12011 1.83356 3.51602 2.27895 3.07062C2.72435 2.62522 3.32844 2.375 3.95833 2.375C4.1017 2.37534 4.24475 2.38832 4.38583 2.41379L7.41 2.96796C7.77386 3.03454 8.10292 3.22642 8.34009 3.51029C8.57725 3.79415 8.70753 4.1521 8.70833 4.522V16.568ZM17.4167 13.2945C17.4168 13.8507 17.2216 14.3893 16.8653 14.8163C16.5089 15.2434 16.014 15.5319 15.4668 15.6315L10.4912 16.5355C10.4247 16.5474 10.3582 16.5585 10.2917 16.568V4.522C10.2916 4.15119 10.4217 3.79213 10.6593 3.50741C10.8968 3.22269 11.2268 3.03038 11.5916 2.964L14.6165 2.40983C14.9591 2.34751 15.3112 2.36127 15.6479 2.45014C15.9845 2.53901 16.2975 2.70082 16.5647 2.92411C16.8319 3.14739 17.0467 3.42669 17.1939 3.74223C17.3411 4.05776 17.4172 4.40181 17.4167 4.75V13.2945Z"
        fill="currentColor"
      />
    </svg>
  );
};
